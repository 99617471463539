$success-color: #43ac6a;
$base-bg-color: #005512;
$hover-bg-color: #038820;

.add-to-cart-button,
.add-to-cart-button--placeholder {
  display: none;
  max-width: 15rem;
  padding: 1rem;
  grid-area: button;
  justify-self: start;
  background-color: $base-bg-color;
  border-color: $hover-bg-color;

  &:hover {
    background-color: $hover-bg-color;
    border-color: $success-color;
  }

  &--placeholder {
    background-color: transparent;
  }

  &.atc-button-success,
  &.atc-button-success:hover {
    background-color: $success-color;
    border-color: $success-color;
    color: $white;
  }

  &.enabled,
  &--placeholder {
    display: block;
  }
}
