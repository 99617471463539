.atc-wrapper,
.atc-wrapper--placeholder {
  display: none;
  grid-template-columns: 1fr 0.3fr 1.5fr;
  grid-template-rows: auto;
  gap: 0.5rem 1rem;
  grid-template-areas:
    'pricing form button'
    '. . link';
  align-items: baseline;

  &.active,
  &--placeholder {
    display: grid;
  }
}
