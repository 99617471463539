.atc-form,
.atc-form--placeholder {
  display: none;
  grid-area: form;

  &.enabled,
  &--placeholder {
    display: flex;
  }

  label {
    display: inline-block;
    margin: 0;
    font-size: 1rem;
    line-height: 2;
  }

  input {
    height: auto;
    margin: 0 0.5rem;
    padding: 0.5rem 0;
    text-align: center;
    width: 2rem;
    display: inline-block;
  }
}
