section.two-column {
  .text-inner {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1200px;

    .body {
      columns: 2;

      p:first-of-type {
        margin-top: 0;
      }

      h3 {
        letter-spacing: 2px;
        color: $darkerGrey;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

  @media screen and (max-width: 479px) {
    .text-inner {
      .body {
        columns: 1;
      }
    }
  }
}
