.atc-pricing,
.atc-pricing--placeholder {
  display: none;
  grid-area: pricing;
  justify-self: end;
  align-self: center;

  &.enabled,
  &--placeholder {
    display: block;
  }

  &--placeholder {
    height: 4.5rem;
    width: 200px;
  }

  .atc-avail-code {
    display: block;
    font-size: 0.8rem;
    line-height: 1;
  }

  .atc-display-price {
    display: inline-block;
    font-size: 1.6rem;
  }

  .atc-sells-in {
    display: inline-block;
    font-size: 1rem;
  }
}
