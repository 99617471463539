#search {
  section {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 20px;

    &:last-of-type {
      margin-bottom: 10rem;
    }

    &#heading {
      margin: 15rem auto 4rem;
      text-align: center;

      h1 {
        font-size: 2.35em;
        font-weight: 300;
        line-height: 1.2;
        line-height: 1;
        margin-bottom: 0;
      }

      h2 {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1;
      }
    }

    &#products {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: $bpMobile) {
  #search {
    section {
      &#heading {
        margin-top: 0;
      }
    }
    #products {
      padding: 0;
    }
  }
}
