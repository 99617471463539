#page {
  section {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 20px;

    &:last-of-type {
      margin-bottom: 10rem;
    }

    &#heading {
      margin: 5rem auto 4rem;
      text-align: center;

      h1 {
        font-size: 2.35em;
        font-weight: 300;
        line-height: 1.2;
        line-height: 1;
        margin-bottom: 0;
      }

      h2 {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1;
      }
    }

    &#content.about {
      margin-top: 10rem;
    }

    h3 {
      color: #222;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 600;
      line-height: 1;
    }

    p {
      &.two-col {
        columns: 2;

        @media screen and (max-width: $bpMedium) {
          columns: 1;
        }
      }
    }

    a {
      color: $black;
      text-decoration: none;

      &:hover {
        color: $darkGrey;
      }
    }
    table {
      margin: 0 -2rem;
      border-spacing: 2rem;
      figure {
        margin: 0;
      }

      td {
        color: $textGrey;

        h2 {
          margin: 0;
          color: $black;
        }
      }

      @media screen and (max-width: $bpMobile) {
        tr {
          display: flex;
          flex-direction: column;

          td {
            figure {
              max-width: 100% !important;
              img {
                width: 100% !important;
                height: auto !important;
                margin: 1rem 0;
              }
            }
          }
        }
      }
    }

    &#downloads {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid $grey;

      .download-container {
        padding-top: 4rem;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -0.5rem;

        a {
          display: flex;
          border: 1px solid $black;
          border-radius: 100px;
          padding: 1rem 2rem;
          white-space: nowrap;
          text-transform: uppercase;
          font-weight: 600;
          margin: 0.5rem;
          align-items: center;

          svg {
            height: 25px;
            width: 25px;
            padding-right: 1rem;
          }

          &:hover {
            background-color: $black;
            color: $white;

            svg {
              fill: $white;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $bpMedium) {
  #page {
    section {
      &#heading {
        margin-top: 0;
      }
    }
  }
}

@media only screen and (max-width: $bpSmall) {
  #page {
    section {
      &#downloads {
        .download-container {
          a {
            white-space: initial;
            font-size: 12px;
          }
        }
      }
    }
  }
}
