.swiper-button-disabled {
  display: none;
}

.swiper-container {
  width: 100%;
  border-bottom: 6px $red solid;

  .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      background: $white;
      border: 1px solid;
      border-color: $black;
      width: 15px;
      height: 15px;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background: $red;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    color: $red;
    background-color: $white;
    border-radius: 5px;
    padding: 0 0.5em;
    &::after {
      font-size: 0.85rem;
      font-weight: bold;
    }

    &:hover {
      color: $white;
      background-color: $red;
    }
  }

  .swiper-slide {
    position: relative;

    .background {
      width: 100%;
      height: auto;
      z-index: 5;
    }
    .content {
      width: 600px;
      z-index: 10;
      color: $white;
      position: absolute;
      top: 20%;
      left: 75px;
      background: rgba(159, 22, 18, 0.8);
      padding: 20px;

      h2 {
        font-size: 2.8em;
        font-weight: 300;
        line-height: 1.2;
        margin: 10px 0;
      }
      h3 {
        font-size: 1.25em;
        font-weight: 600;
        line-height: 1;
      }
      p {
        color: inherit;
      }
      .see-more {
        display: inline-block;
        color: inherit;
        text-decoration: none;
        border: 1px $white solid;
        font-size: 0.95em;
        font-weight: 600;
        padding: 0.75em 1.75em;
        border-radius: 3px;
      }
    }
  }
}

.swiper-products {
  max-width: 100%;
  height: 475px;
  position: relative;
  background-color: $lighterGrey;

  .swiper-button-prev,
  .swiper-button-next {
    color: $red;
    background-color: $white;
    border-radius: 5px;
    padding: 0 0.5em;
    &::after {
      font-size: 0.85rem;
      font-weight: bold;
    }

    &:hover {
      color: $white;
      background-color: $red;
    }
  }
  .swiper-button-disabled {
    display: none;
  }

  .swiper-slide {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: $lighterGrey;
    border-left: 2px solid $white;
    border-right: 2px solid $white;

    .img-container {
      position: relative;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 2rem 0;

      .badge {
        position: absolute;
        width: 15%;
        height: auto;
        top: 3%;
        right: 3%;
      }

      .img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    .content {
      z-index: 10;
      color: $darkerGrey;
      text-align: center;
      padding: 0 2rem;

      &.dark {
        color: $black;

        .see-more {
          border-color: $black;
          &:hover {
            color: $white;
            background-color: $red;
          }
        }
      }

      h2 {
        font-size: 1.25em;
        font-weight: 600;
        line-height: 1.2;
        margin: 10px 0;

        a {
          color: inherit;
          text-decoration: none;
        }
      }
      h3 {
        font-size: 0.8em;
        font-weight: 600;
        color: $techGrey;
        line-height: 1;
        text-transform: uppercase;

        a {
          color: inherit;
          text-decoration: none;
        }
      }
      p {
        color: inherit;
      }
      .see-more {
        display: inline-block;
        color: $white;
        text-decoration: none;
        background-color: $mediumDarkGrey;
        font-size: 1rem;
        font-weight: 600;
        padding: 0.75em 2em;
        border-radius: 5px;
        margin-top: 1rem;
        text-transform: uppercase;

        &:hover {
          background-color: $red;
        }
      }
    }
  }
}

.swiper-technologies {
  max-width: 100%;
  height: 320px;
  position: relative;

  .swiper-button-prev,
  .swiper-button-next {
    color: $black;
    background-color: $white;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    padding: 0.75rem;
    border: 2px solid $white;

    &:after {
      font-size: 16px;
      font-weight: bold;
    }

    &:hover {
      color: $white;
      background-color: $black;
    }
  }
  .swiper-button-disabled {
    display: none;
  }

  .swiper-slide {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $lighterGrey;

    .img-container {
      position: relative;
      height: 100px;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 2rem 0;
      margin-bottom: 10%;

      .img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    .content {
      z-index: 10;
      color: $white;
      text-align: center;
      padding: 0 2rem;
      text-decoration: none;

      &.dark {
        color: $darkerGrey;

        .see-more {
          border-color: $black;
          &:hover {
            color: $white;
            background-color: $black;
          }
        }
      }

      h2 {
        font-size: 1.25em;
        font-weight: 400;
        line-height: 1.2;
        margin: 10px 0;
      }
      h3 {
        font-size: 0.8em;
        font-weight: 600;
        color: $techGrey;
        line-height: 1;
        text-transform: uppercase;
      }
      p {
        color: inherit;
      }
      .see-more {
        display: inline-block;
        color: inherit;
        text-decoration: none;
        background-color: $grey;
        font-size: 1.35rem;
        font-weight: 600;
        padding: 0.5em 1.75em;
        border-radius: 100px;
        margin-top: 1rem;
        text-transform: uppercase;

        &:hover {
          background-color: $lightGrey;
        }
      }
    }
  }
}

.swiper-reviews {
  max-width: 1200px;
  margin: 0 auto;
  overflow-x: hidden;
  position: relative;

  .swiper-button-prev,
  .swiper-button-next {
    color: $black;
    background-color: $white;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    padding: 0.75rem;
    border: 2px solid $white;

    &:after {
      font-size: 16px;
      font-weight: bold;
    }

    &:hover {
      color: $white;
      background-color: $black;
    }
  }
  .swiper-button-disabled {
    display: none;
  }

  .swiper-slide {
    position: relative;

    .review-link-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-decoration: none;

      .img-container {
        height: 70px;
        border-bottom: 1px solid $lightGrey;
        margin: 0 0 2rem 0;
        padding: 2rem 0;
        .review-img {
          height: 100%;
          width: auto;
        }
      }

      .source {
        color: $darkerGrey;
        text-decoration: none;
        font-size: 1.175rem;
      }

      .excerpt {
        color: $textGrey;
        font-style: oblique;
        font-size: 0.85rem;
      }
    }
  }
}

.swiper-product-images {
  max-width: 100%;
  position: relative;
  overflow-x: hidden;

  .swiper-button-prev,
  .swiper-button-next {
    color: $black;
    background-color: $white;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    padding: 0.75rem;
    border: 2px solid $black;

    &:after {
      font-size: 16px;
      font-weight: bold;
    }

    &:hover {
      color: $white;
      background-color: $black;
    }
  }
  .swiper-button-disabled {
    display: none;
  }

  .swiper-slide {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      img {
        width: 50%;
        height: auto;
      }
    }
  }
  .close {
    display: none;
  }

  &.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    background: rgba(255, 255, 255, 0.85);

    .swiper-slide {
      a {
        width: auto;
        height: auto;
        cursor: default;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 90%;
        }
      }
    }
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
      border: none;
      background: $white;
      padding: 0;
      display: block;
      border-radius: 20px;
      cursor: pointer;
      z-index: 250;
    }
  }
}

@media only screen and (max-width: $bpLarge) {
}

@media only screen and (max-width: $bpMedium) {
  .swiper-container,
  .swiper-about {
    .swiper-slide {
      .content {
        width: 500px;

        h2 {
          font-size: 2.5rem;
        }

        h3 {
          font-size: 1rem;
        }

        p {
          font-size: 0.85rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $bpMobile) {
  .swiper-container,
  .swiper-about {
    border-bottom: none;

    .swiper-pagination-bullets {
      display: none;
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: 50px;
    }
    .swiper-slide {
      height: 100%;

      .background {
      }
      .content {
        width: 100%;
        position: relative;
        color: $white;
        text-align: center;
        top: -10px;
        left: 0;
        transform: translateY(0);
        box-sizing: border-box;
        height: 100%;
        background: rgba(159, 22, 18, 0.9);

        h2 {
          font-size: 2rem;
          text-align: center;
        }

        h3 {
          font-size: 1rem;
          text-align: center;
        }

        p {
          font-size: 0.85rem;
        }
        .see-more {
          display: inline-block;
          color: inherit;
          text-decoration: none;
          border: 1px $white solid;
          font-size: 0.95em;
          font-weight: 600;
          padding: 0.75em 1.75em;
          border-radius: 0;
        }
      }
      .slide-link {
        display: none;
      }
    }
  }
  .swiper-products {
    .swiper-slide {
      border: none;
    }
  }
}
