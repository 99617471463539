body {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  font-size: 17px;
  line-height: 1.6;
  margin: 0;
  background-color: $background;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .page-wrapper {
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
    background-color: $white;
  }
}

#site-header {
  display: flex;
  border-bottom: 1px $lighterGrey solid;
  flex-wrap: wrap;
  position: fixed;
  z-index: 100;
  width: 100%;
  max-width: 1920px;
  background-color: $white;
  top: 0;
  box-shadow: 0 2px 25px -22px #000;

  #site-logo {
    width: 120px;
    flex: 0 0 120px;
    margin: 25px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      width: auto;
      max-width: 100%;
    }
  }
  #show-nav {
    display: none;
  }
  #main-nav {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    > a,
    > button {
      position: relative;
      text-transform: uppercase;
      color: $darkerGrey;
      text-decoration: none;
      flex: 1 1 content;
      white-space: nowrap;
      margin: 0 10px;
      padding: 0;
      cursor: pointer;
      text-align: left;
      font-size: 1rem;
      font-weight: 400;
      font-family: inherit;
      line-height: 1.6;
      background: none;
      border: none;

      &.active {
        &:after {
          content: '';
          position: absolute;
          top: calc(100% + 5px);
          left: 50%;
          transform: translateX(-50%);
          border-bottom: 1rem solid $lighterGrey;
          border-left: 1rem solid transparent;
          border-right: 1rem solid transparent;
        }
      }
    }
    .subnav {
      position: fixed;
      padding: 0;
      z-index: 100;
      background-color: $white;
      width: 100%;
      max-width: 1920px;
      left: 50%;
      transform: translateX(-50%);
      top: 86px;
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      border-top: 1px solid $lighterGrey;

      a {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: $black;
        text-transform: uppercase;
        font-size: 0.85rem;
        font-weight: 600;
        padding: 1rem;
        border-right: 1px solid $lighterGrey;
        border-bottom: 1px solid $lighterGrey;
        width: calc(20% - 2rem - 1px);

        &:hover {
          color: $darkerGrey;
        }

        img {
          width: 100%;
          height: auto;
          margin-bottom: 1rem;
        }

        .product-name {
          text-align: center;

          &.active {
            color: $textGrey;
          }
        }
      }
    }
  }
  #show-mobile-search {
    display: none;
  }
  #site-search,
  #mobile-search {
    margin-left: auto;
    display: flex;
    align-items: center;
    padding: 25px 0;
    background: $white;

    .keywords {
      color: $textGrey;
      flex: 1 1;
      background: none;
      border: none;
      font: inherit;
      padding: 0;
      margin: 0 20px;
      display: block;

      &::placeholder {
        color: $grey;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: $grey;
      }

      &::-ms-input-placeholder {
        color: $grey;
      }
    }
    .submit {
      color: $red;
      flex: 0 1 20px;
      background: none;
      border: none;
      display: block;
      padding: 0;
      margin: 0 20px 0 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  #mobile-search {
    display: none;
  }

  #page-scroll {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: $mediumDarkGrey;

    .scroll-inner {
      margin: 1rem 0;
      a {
        text-decoration: none;
        color: $lighterGrey;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 700;
        letter-spacing: 2px;

        &:not(:last-of-type) {
          margin-right: 1rem;
        }

        &.active,
        &:hover {
          color: $white;
        }
      }
    }
  }
  #goto-account,
  #goto-cart {
    color: $darkerGrey;
    flex: 0 1 20px;
    background: $white;
    border: none;
    display: block;
    padding: 0;
    margin: 0 20px 0 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
    }
  }

  #goto-cart {
    position: relative;
    #cart-item-counter {
      background: #333;
      border-radius: 50%;
      color: #fff;
      display: none;
      font-size: 0.875rem;
      height: 1.2rem;
      line-height: 1.3;
      position: absolute;
      left: 0;
      text-align: center;
      top: 3.5rem;
      width: 1.2rem;

      &.active {
        display: block;
      }
    }
  }
}

#mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $darkGrey;
  color: $lightGrey;
  z-index: 150;
  padding-bottom: 2rem;
  display: none;

  a,
  button {
    color: $lightGrey;
    text-decoration: none;
    border: none;
    border-bottom: 1px $darkerGrey solid;
    padding: 0.75rem;
    display: block;
    background: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    font-family: inherit;
    line-height: 1.6;
    cursor: pointer;

    &:after {
      content: '\203a';
      float: right;
      font-weight: 600;
    }

    &.close-nav {
      svg {
        height: 25px;
        width: 25px;
        font-weight: bold;
      }
      &:after {
        content: '';
      }
    }
  }
  .subnav {
    left: 0%;

    button {
      background: $darkerGrey;

      &:after {
        content: '';
      }
      &:before {
        content: '\2039';
        float: left;
        font-weight: 600;
        margin-right: 0.5rem;
      }
    }
  }
}

.page-content {
  margin-top: 105px;
  overflow: hidden;

  &.scroll {
    margin-top: 165px;
  }

  h2 {
    font-size: 2.35em;
    font-weight: 300;
    line-height: 1.2;
  }
  p {
    color: $textGrey;
  }
  section {
    margin-top: 5em;
    margin-bottom: 5em;
  }
}
#site-footer {
  #footer-feature-nav {
    border-top: 1px $lighterGrey solid;
    border-bottom: 1px $lighterGrey solid;
    overflow: hidden;

    .inner {
      max-width: 1200px;
      margin: 15px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        flex: 1 1;
        color: $black;
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;

        &:hover {
          opacity: 0.75;
          transition: 0.25s;
        }
        svg {
          width: 50px;
          height: 50px;
          color: $red;
          vertical-align: middle;
          margin: 0 10px;
        }
      }
    }
  }
  .main {
    overflow: hidden;
    padding: 4em 0 0 0;
    border-bottom: 1px $lighterGrey solid;

    .inner {
      margin: 15px auto;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .footer-nav {
        flex: 0 10 25%;

        h3 {
          color: $red;
          font-size: 1em;
          font-weight: 700;
          text-transform: uppercase;
        }
        a {
          display: block;
          color: $darkerGrey;
          text-decoration: none;

          svg {
            color: $white;
            height: 1.25rem;
            margin-right: 0.25rem;
            vertical-align: text-bottom;
          }
        }
      }
      .price-disclaimer {
        width: 100%;
        color: $grey;
        margin: 4em 0 5px 0;
      }
    }
  }
  .bottom {
    padding: 1em 0 4em 0;

    .inner {
      margin: 15px auto;

      p {
        color: $textGrey;
        font-size: 0.85em;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}

@media only screen and (max-width: $bpLarge) {
  #site-header {
    #site-logo {
      width: 100px;
      flex: 0 0 100px;
      margin: 25px 15px;
    }
    #main-nav {
      > li {
        margin: 0 7px;
      }
    }
    #show-mobile-search {
      color: $darkerGrey;
      flex: 0 1 20px;
      background: $white;
      border: none;
      display: block;
      padding: 0;
      margin: 0 20px 0 0;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        flex: 0 0 20px;
        width: 20px;
        height: 20px;
      }
    }
    #site-search {
      display: none;
    }
    #mobile-search {
      display: flex;
      position: absolute;
      width: 100%;
      top: 100%;
    }
  }
}

@media only screen and (max-width: $bpMedium) {
  #site-header {
    #site-logo {
      margin: 25px auto;
      width: 150px;
      flex: 0 0 150px;
    }
    #show-nav {
      display: block;
      background: none;
      border: none;
      padding: 0 15px;
      cursor: pointer;

      svg {
        width: 25px;
        height: 25px;
      }
    }
    #main-nav {
      display: none;
    }
    #show-mobile-search,
    #goto-account,
    #goto-cart {
      margin: 0;
      padding: 0 15px;
    }
    #goto-cart {
      #cart-item-counter {
        left: 0.9rem;
      }
    }
    #page-scroll {
      display: none;
    }
  }
  #mobile-nav {
    display: block;
  }
  .page-content {
    &.scroll {
      margin-top: 105px;
    }
  }
  #site-footer {
    #footer-feature-nav {
      .inner {
        flex-wrap: wrap;

        a {
          flex: 1 1 50%;
          margin: 0.5rem 0;
        }
      }
    }
    .main {
      .inner {
        .footer-nav {
          flex: 0 1 33%;
        }
      }
    }
  }
}

@media only screen and (max-width: $bpMobile) {
  .page-content {
    h2 {
      font-size: 1.5em;
    }
    section {
      margin-top: 1em;
      margin-bottom: 1em;
      border-top: 1px $lighterGrey solid;
    }
  }
  #site-header {
    #site-logo {
      margin: 10px auto;
      width: 100px;
      flex: 0 0 100px;
    }
    #goto-account,
    #goto-cart,
    #show-mobile-search {
      margin: 0;
      padding: 0 10px;
    }
    #goto-cart {
      #cart-item-counter {
        left: 0.6rem;
        top: 2.2rem;
      }
    }
  }
  #site-footer {
    #footer-feature-nav {
      font-size: 0.85rem;

      .inner {
        a {
          svg {
            width: 20px;
            height: 20px;
            margin: 0 5px;
          }
        }
      }
    }
    .main {
      .inner {
        flex-wrap: wrap;

        .footer-nav {
          flex: 0 0 100%;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
