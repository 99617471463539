#product {
  .product-hero {
    background: radial-gradient(ellipse at center, #fff 0, #e0e0e0 100%);
    padding: 3rem;
    border-bottom: solid 8px #9f1612;

    .hero-inner {
      margin: 0 auto;
      padding: 0 15px;
      max-width: 1200px;
      display: flex;

      & > div {
        flex-basis: 50%;
        max-width: 50%;
      }

      .product-images {
        margin: 1.5rem 0;
        display: flex;

        .gallery-triggers {
          min-width: 18%;
          display: flex;
          flex-direction: column;
          .gallery-img {
            display: flex;
            height: 105px;
            justify-content: center;
            align-items: center;
            background-color: $white;
            border: 1px solid $lighterGrey;

            &:not(:last-of-type) {
              margin-bottom: 1rem;
            }
            img {
              max-height: 80%;
              height: auto;
              width: auto;
              max-width: 80%;
            }

            &.gallery-open {
              flex-direction: column;
              text-decoration: none;
              color: $textGrey;
              font-weight: bold;
              text-transform: uppercase;
              cursor: pointer;

              .icon {
                font-size: 2em;
                line-height: 1;
              }
              .text {
                line-height: 1;
                font-size: 0.85em;
              }
            }
          }
        }
      }

      .product-details {
        .details-control {
          padding: 1.5rem 0;
          border-bottom: 1px solid $lightGrey;
          margin-bottom: 1.5rem;

          a {
            color: $techGrey;
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 0.95rem;

            svg {
              height: 0.65rem;
            }

            &:hover {
              color: black;
            }
          }
        }

        .details-heading {
          display: flex;
          align-items: center;

          .heading-info {
            h2 {
              font-size: 3rem;
              margin: 0 0 0.5rem 0;
            }
            h3 {
              letter-spacing: 2px;
              text-transform: uppercase;
              font-weight: 600;
              margin: 0;
              line-height: 1;
            }
          }

          .heading-badge {
            max-width: 190px;
            margin-left: 2rem;
            img {
              width: 100%;
              height: auto;
            }
          }
        }

        .details-body {
          color: $darkerGrey;
        }

        .details-finish {
          display: flex;
          padding: 1rem 0;
          margin: 2rem 0 1rem;
          border-style: solid;
          border-color: $lightGrey;
          border-width: 1px 0;

          .finish-option {
            display: flex;
            align-items: center;
            margin-right: 1.5rem;
            cursor: pointer;
            border: none;
            background-color: transparent;
            .finish-icon {
              height: 50px;
              width: 50px;
              border: 1px solid $lightGrey;
              border-radius: 100%;
              background-size: 110% 110%;
              background-position: center center;
              margin-right: 0.5rem;

              &.black {
                background-image: url('/images/finishes/Piano-black.jpeg');
              }

              &.mocca {
                background-image: url('/images/finishes/mocca.jpeg');
              }

              &.white {
                background-image: url('/images/finishes/Piano-white.jpeg');
              }
            }
            span {
              text-transform: uppercase;
              font-weight: bold;
              font-size: 0.8rem;
              color: $textGrey;
            }

            &.selected,
            &:hover {
              span {
                color: $black;
              }
            }
          }
        }

        .details-links {
          display: flex;
          justify-content: flex-end;
          margin: 0 1rem;
        }
      }
    }
  }

  #reviews {
    text-align: center;
    margin-bottom: 1rem;
    h2 {
      margin-bottom: 0;
    }
    > h3 {
      margin-top: 0;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 600;
      color: $darkerGrey;
    }
    .swiper-button-prev,
    .swiper-button-next {
      top: 133px;
    }
  }

  .slideshow {
    margin-top: 8rem;
  }

  #technical-specifications {
    .tech-specs-inner {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      h3 {
        border-bottom: 1px solid $lightGrey;
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
        font-size: 2.35em;
        font-weight: 300;
        line-height: 1.2;

        @media screen and (max-width: $bpMedium) {
          font-size: 1.5em;
        }
      }

      .specs-body {
        h4 {
          text-transform: uppercase;
          color: $textGrey;
          font-size: 0.85rem;
          letter-spacing: 2px;
          margin: 0.75rem 0;
        }

        p {
          color: $black;
          margin: 0 0 1.5rem 0;
        }
      }

      & > div {
        flex-basis: 48%;
      }

      .highlights {
        .highlight {
          h4 {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin: 0;
            text-transform: uppercase;
            color: $textGrey;
            font-size: 0.85rem;
            letter-spacing: 2px;
            svg {
              margin-right: 0.5rem;
              max-height: 16px;
              max-width: 16px;
            }

            border-bottom: 1px solid $lightGrey;
            padding: 1.5rem 0;
          }
          &.open {
            h4 > svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  #downloads {
    max-width: 90%;
    margin: 0 auto;
    .downloads-inner {
      max-width: 1200px;
      margin: 0 auto;

      h2 {
        border-bottom: 1px solid $lightGrey;
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
      }

      .downloads-wrapper {
        display: flex;
        flex-wrap: wrap;
        .download {
          display: flex;
          border: 1px solid $lightGrey;
          flex-basis: 30%;
          height: 68px;
          text-decoration: none;
          margin-bottom: 1.5rem;

          &:not(:nth-of-type(3n)) {
            margin-right: calc(5% - 6px);
          }

          .icon {
            display: flex;
            align-items: center;
            padding: 0 22.5px;
            svg {
              height: 15px;
              width: 15px;
              fill: #e75c11;
            }
          }
          .details {
            border-left: 1px solid $lightGrey;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 1.25rem;

            .name {
              color: $black;
              line-height: 1;
            }

            .size {
              text-transform: uppercase;
              color: $textGrey;
              font-size: 0.85rem;
              letter-spacing: 2px;
              font-weight: bold;
              line-height: 1.2;
            }
          }

          &:hover {
            .details {
              .name {
                color: #e75c11;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $bpMedium) {
  #product {
    .product-hero {
      padding: 1rem 0.25rem;

      .hero-inner {
        display: block;

        & > div {
          max-width: 100%;
        }

        .product-images {
          margin: 0;

          .gallery-triggers {
            display: none;
          }
        }
      }
    }
    #technical-specifications {
      .tech-specs-inner {
        display: block;

        & > div {
          width: 90%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: $bpSmall) {
  #product {
    #downloads {
      .downloads-inner {
        .downloads-wrapper {
          .download {
            flex-basis: 100%;
            margin-right: 0;

            &:not(:nth-of-type(3n)) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  #reviews {
    .swiper-slide {
      a {
        .excerpt {
          margin: 0 30px;
        }
      }
    }
  }
}
