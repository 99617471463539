form {
  .freeform-row {
    .freeform-column {
      input {
        border-color: $lightGrey;
        border-radius: 100px;
        border-style: solid;
        border-width: 1px;
        padding: 1rem;

        &::placeholder {
          color: $lightGrey;
        }
      }

      label {
        color: $textGrey;
        font-weight: normal;
      }

      .freeform-instructions {
        color: $black;
      }

      textarea {
        border-color: $lightGrey;
        border-radius: 25px;
        border-style: solid;
        border-width: 1px;
        padding: 1rem;
        font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
        &::placeholder {
          color: $lightGrey;
        }
      }

      button {
        color: white;
        text-decoration: none;
        background-color: $darkerGrey;
        border-color: $darkerGrey;
        font-size: 1rem;
        font-weight: 300;
        padding: 1em 1.75em;
        border-radius: 100px;
        margin-top: 1rem;
        text-transform: uppercase;
        border-style: solid;
        margin: 0 auto;
        cursor: pointer;

        &:hover {
          background-color: $darkGrey;
          border-color: $darkGrey;
        }
      }
    }
  }
}

@media only screen and (max-width: $bpMobile) {
  form {
    .freeform-row {
      display: block;
    }
  }
}
