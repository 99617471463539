#page-content.page-content {
  > .content {
    h2 {
      text-align: center;
    }

    h3 {
      text-align: center;
      text-transform: uppercase;
      font-size: 1.2em;
      font-weight: 600;
      line-height: 1;
      margin-top: 0;
      letter-spacing: 2px;
    }
    p {
      &:first-child {
        margin-top: 0;
      }
    }
    .body {
      .two-columns {
        columns: 2;
      }
    }
  }
}
