#page-home.page-content {
  > .content {
    h2 {
      text-align: center;
    }
  }

  .container {
    width: calc(100% + 10px);
    display: flex;
    margin: 0 -5px;

    &.outer {
      height: 640px;
    }
    .block {
      margin: 5px;
      position: relative;
      background-size: cover;

      &.narrow {
        flex: 1 1;
      }
      &.wide {
        flex: 2 1;
      }
      &.container {
        margin: 0;
        flex-wrap: wrap;

        .block {
          &.narrow {
            flex-basis: 40%;
          }
          &.wide {
            flex-basis: 90%;
          }
        }
      }
      .title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        color: $white;
        text-decoration: none;
        padding: 10px;
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.8);
        transition: background 0.25s;

        .subtitle {
          display: block;
          color: $lightGrey;
          font-size: 0.85em;
          text-transform: uppercase;
          font-weight: 700;
        }
        svg {
          position: absolute;
          right: 10px;
          bottom: 10px;
          width: 22px;
          height: 22px;
        }
      }
    }
    a:hover > .title {
      background: rgba(0, 0, 0, 1);
      transition: background 0.25s;
    }
  }
}

@media only screen and (max-width: $bpLarge) {
}

@media only screen and (max-width: $bpMedium) {
}

@media only screen and (max-width: $bpMobile) {
  #page-home.page-content {
    .container {
      flex-wrap: wrap;

      &.outer {
        height: auto;
      }
      .block {
        margin: 5px;
        height: 300px;
        background-size: cover;
        background-position: 50% 50%;
        overflow: hidden;

        &.narrow {
        }
        &.wide {
          flex: 2 1 100%;
        }
        &.container {
          height: auto;
        }
      }
    }
  }
}
