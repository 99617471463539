// Colors
$white: #fff;
$background: #f2f2f2;
$lighterGrey: #eeeeee;
$lightGrey: #b2b2b2;
$grey: #8b8b8b;
$textGrey: #737373;
$techGrey: #6b6b6b;
$mediumDarkGrey: rgb(96, 96, 96);
$darkGrey: #272727;
$darkerGrey: #222;
$black: #000;
$red: #9f1612;

// Responsive Breakpoints
$bpLarge: 1500px;
$bpMedium: 1200px;
$bpMobile: 1024px;
$bpSmall: 768px;

.centered-content {
  max-width: 1180px;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;
}

figure {
  img {
    max-width: 100%;
    height: auto;
  }
}

.scroll-pad {
  &:before {
    content: '';
    display: block;
    height: 185px;
    margin-top: -185px;
    visibility: hidden;
  }
}

.button {
  display: inline-block;
  color: white;
  text-decoration: none;
  background-color: $grey;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.5em 1.75em;
  border-radius: 25px;
  border-width: 1px;
  margin-top: 1rem;
  text-transform: uppercase;

  &:hover {
    background-color: $red;
  }
}

ul {
  list-style-type: square;

  li {
    &::marker {
      color: $lightGrey;
    }
  }
}

// Technology Modal
.technology-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  background: rgba(0, 0, 0, 0.25);

  .inner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 20px;
    background: $white;
    box-sizing: border-box;
    border-top: 50px $lighterGrey solid;
    border-bottom: 50px $lighterGrey solid;

    .heading {
      overflow: hidden;

      img {
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
        max-width: 120px;
        max-height: 120px;
      }
      h2 {
        text-align: left;
        margin-top: 1rem;
      }
      h3 {
        text-align: left;
        margin-top: 0;
      }
    }
    .summary {
      clear: both;
      border-top: 1px $lightGrey solid;
      margin-top: 20px;
      padding-top: 20px;
    }
    .close {
      position: absolute;
      top: -40px;
      right: 10px;
      width: 30px;
      height: 30px;
      border: none;
      background: none;
      padding: 0;
    }
  }
}

// effects
.effect {
  &--shimmer {
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(200, 200, 200, 0.2) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      height: 100%;
      width: 100%;
      z-index: 1;
      animation: shimmer 2s infinite;
    }
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@media screen and (max-width: $bpMobile) {
  .technology-overlay {
    .inner {
      width: 90%;
      max-height: 90vh;

      .summary {
        max-height: 68%;
        overflow-y: scroll;
      }
    }
  }
}

html {
  scroll-behavior: smooth;
}
